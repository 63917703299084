/**
 * 存放接口名称
 */
export default {
    auth: {
        login: '/api/auth/login',
        getCaptcha: '/api/auth/captcha',
        checkCaptcha: '/api/auth/checkCaptcha',
        getCode: '/api/auth/getVerCode',
        resetPwd: '/api/auth/resetPwd', //重置密码
        updatePwd: '/api/auth/pwd', //修改密码
        signOut: '/api/auth/signOut',
        signUp: '/api/auth/signUp',
        inviteFriends: '/api/auth/user/inviteFriends',
        inviteRecords: '/api/auth/user/inviteRecord',
        updatePwd: '/api/auth/user/pwd',
        bindToken :'/api/auth/user/related',
        getSessionUser: '/api/auth/user/session', //获取用户session
        bindAccount: '/api/auth/bindAccount',
    },
    index: {
        index: '/api/index/index',
        banner: '/api/index/banner',
        frontIndex:'api/index/frontIndex'
    },
    downLoad: {
        buy: '/api/download/buy', // 图片购买下载接口
        buyWait: '/api/download/buy_wait', // 图片购买下载接口-等待模式
        check:'/api/download/check', //状态查询接口
        hasBlance: '/api/download/hasBlance', // 判断余额是否充足接口
        searchImage: '/api/download/search', // 图片资源搜索接口
        freeSearchImage: '/api/download/freeSearch', // 图片资源搜索接口
        reDownload:'/api/download/reDownload', //图片重新下载接口
        tempLogin:'/api/temp/', //次卡访问下载接口
        tempbuy: '/api/download/buy_temp', // 图片购买下载接口
        getStatus:'api/download/getShutterStatus' //获取状态接口
    },
    coupon: { // 优惠券
        exchange: '/api/coupon/exchange', // 兑换优惠券
        getCoupon: '/api/coupon/getCoupon', // 领取优惠券
        list: '/api/coupon/list', // 获取优惠券列表
        useCoupon: '/api/coupon/useCoupon', // 使用优惠券

        userCouponlist: '/api/coupon/userCouponlist', // 获取用户优惠券列表
    },
    order:{//订单信息
        consumeList:'/api/order/downloadConsumeList', //下载订单历史，
        chargeList:'/api/order/recOrderList' //充值订单历史
    },
    brand:{//素材网站
        chargesList:'/api/brand/chargesList', //各素材网站费用明细
        brandsList:'/api/brand/brandsList' //各网站列表
    },
    recharge:{//充值卡券
        cradCharge:'/api/recharge/card/recharge',//充值接口
        cradChargeList:'', //用户充值历史
        cardImage:'/api/recharge/card/imageCode',//图片验证码
        paySuccess:'/api/recharge/paySuccess', //支付成功接口
        vipPaySuccess:'/api/recharge/vipPaySuccess',//vip支付成功接口
        vipChargeList:'/api/recharge/vipChargeList',//vip充值记录
        vipYePaySuccess: '/api/recharge/vipYePaySuccess', // 余额支付成功
        pfjRecharge:'/api/recharge/hpjZfbReadyRecharge',//虎皮椒充值
    },
    pay:{ //支付卡券
        getERCode:'/api/pay/readyPay', //获取支付二维码
        getVIPERCode:'/api/pay/vipReadyPay', //获取vip支付二维码
        getZFBERCode:'/api/pay/zfbOfficialReadyPay', //获取支付宝二维码
        queryStatus:'/api/pay/queryPayStatusByOrderId',//定时查询支付状态
        queryStatusNew:'/api/pay/queryPayStatusByOrderIdNew',//定时查询支付状态new
        queryStatusZFB:'/api/pay/queryZfbPayStatusByOrderId',//定时查询支付宝支付状态
        updateStatus:'/api/pay/updatePayStatus', //超过3分钟后则更新支付状态
    },
    goods:{//商品类
        goodsIndex:'/api/goods/index', //首页商品列表
        goodsCategory:'/api/goods/category', //分类下的商品
        goodsInfo:'/api/goods/detail', //商品详细数据
        goodsList:'/api/goods/list', //商品列表
    },
    ads:{//广告
        queryAdInfo:'/api/ads/info', //首页商品列表
    }
}
